@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

.no-aspect {
    aspect-ratio: unset !important;
}

/* .debug {
    outline: 1px solid  #0ea5e9;
} */